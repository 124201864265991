<template>
  <div class="mx-2">
    <div class="col-12">
      <div class="row mb-2">
        <div class="col col-12 col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <b-button :to="{name: `${prefix}-category-create`}" variant="primary">
            <span class="text-nowrap">Добавить Категорию</span>
          </b-button>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
                v-model="form.external.searchQuery"
                @change="getExternalCategories"
                class="d-inline-block mr-1"
                placeholder="Поиск..."
            />
          </div>
        </div>
      </div>
      <div class="mx-2 mb-2">
        <div class="row">
          <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Показано с {{ externalCategories.from }} по {{ externalCategories.to }} из {{ externalCategories.total }} записей
            </span>
          </div>
          <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="form.external.currentPage"
                :total-rows="externalCategories.total"
                :per-page="externalCategories.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
      <div class="position-relative table-responsive">
        <table class="table b-table">
          <thead>
          <tr>
            <th>#</th>
            <th>Название</th>
            <th>Привязанные категории</th>
            <th>Путь / Url</th>
            <th colspan="2">Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="category in externalCategories.data" :key="category.id">
            <td>{{ category.id }}</td>
            <td>
              <router-link :to="{ name: `${prefix}-categories`, params: { id: category.id } }">
                {{ category.name }}
              </router-link>
            </td>
            <td>{{ category.categories_count }}</td>
            <td>
              <router-link :to="{ name: `${prefix}-categories`, params: { id: category.parent_id } }">
                {{ category.full_parent_path }}
              </router-link>
              <br>
              {{ category.url }}
            </td>
            <td>
              <router-link
                  :to="{ name: `${prefix}-category-update`, params: { id: category.id }}"
                  class="btn btn-info"
              >
                Редактировать
              </router-link>
            </td>
            <td>
              <button class="btn btn-danger" @click="remove(category.id)">Удалить</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <div class="row">
          <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Показано с {{ externalCategories.from }} по {{ externalCategories.to }} из {{ externalCategories.total }} записей
            </span>
          </div>
          <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="form.external.currentPage"
                :total-rows="externalCategories.total"
                :per-page="externalCategories.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BPagination } from 'bootstrap-vue';

export default {
  name: 'ExternalServiceConfiguration',
  props: ['externalServiceId', 'prefix'],
  components: {
    BFormInput,
    BButton,
    BPagination,
  },
  data() {
    return {
      breadcrumbs: [],
      configuration: null,
      form: {
        external: {
          searchQuery: null,
          currentPage: 1,
        },
      },
      externalCategories: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      selectedCategory: null,
    };
  },
  watch: {
    'form.external.currentPage': {
      async handler(newValue) {
        this.externalCategories.current_page = newValue;
        await this.getExternalCategories();
      },
    },
    '$route.params.id': {
      handler() {
        this.getExternalCategories();
      },
      immediate: true,
    },
    externalServiceId: {
      async handler() {
        await this.getExternalCategories();
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getExternalCategories();
  },
  methods: {
    async getExternalCategories() {
      this.externalCategories = (await this.$api.external.categories.list(this.externalServiceId, {
        ...(this.externalCategories.current_page > 1 && { page: this.externalCategories.current_page }),
        limit: 25,
        ...(this.$route.params.id && { parent_id: this.$route.params.id }),
        ...(this.form.external.searchQuery && { search: this.form.external.searchQuery }),
      })).data;
    },
    async remove(id) {
      try {
        await this.$api.external.categories.remove(id);
      } catch (e) {
        this.$bvToast.toast(e.response.data, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      await this.getExternalCategories();
    },
  },
};
</script>

<style scoped>
</style>
